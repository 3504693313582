//
// Header
//

.header {
    position: relative;
}
.bg-gradient-info{
    background:inear-gradient(87deg,black,white 100%) !important

}
